// used by Articles and FAQ answers to set styling of inner HTML content fetched from API.
import { css } from '@emotion/core'
import {
  colors,
  breakpoints,
  misc,
  mixins,
  spacer,
} from '@dqp/common/styles/utilities'

export default css`
  color: ${colors.black};
  line-height: 1.6;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: Loew;
  }
  h1 {
    ${breakpoints({
      fontSize: [28, 28, 38, 38, 38],
      lineHeight: [1.34, 1.34, 1.34, 1.34, 1.34],
    })}
  }

  h2 {
    ${breakpoints({
      fontSize: [24, 24, 28, 28, 28],
      lineHeight: [1.05, 1.05, 1.05, 1.05, 1.05],
    })}
  }
  h3 {
    ${breakpoints({
      fontSize: [20, 20, 20, 24, 24],
      lineHeight: [1.05, 1.05, 1.05, 1.05],
    })}
  }

  h4 {
    font-size: 20px;
  }
  h5 {
    font-size: 18px;
  }
  h6 {
    font-size: 16px;
  }

  ul,
  ol {
    padding-left: 30px;
    margin-bottom: 1rem;
  }
  ul {
    list-style-type: circle;
  }

  blockquote {
    font-style: italic;
    p {
      margin-bottom: 0;
    }
    background: ${colors.greyLightest};
    position: relative;
    border-left: 5px solid ${colors.greyLight};
    display: block;
    ${spacer.padL10};
    ${spacer.padBT10}
  }
  pre {
    border-radius: ${misc.borderRadius.lg};
    background: ${colors.greyLightest};
    ${spacer.padL10};
    ${spacer.padBT10};
  }
  a {
    text-decoration: none;
    color: ${colors.black};
    border-bottom: 2px solid ${colors.black};
    :hover {
      opacity: 0.7;
    }
    ${mixins.transition()};
  }
  hr {
    background-color: ${colors.greyMedium};
    height: 1px;
    border: none;
  }
  img {
    display: block;
    width: 100% !important;
    height: auto !important;
    margin: auto;
    border-radius: ${misc.borderRadius.lg};
    border: 1px solid rgba(0, 0, 0, 0.3);
    ${spacer.mrBT40};
  }
  div.embeddedContent {
    position: relative;
    display: block;
    width: 100%;
    padding-bottom: 56.25%;
    ${spacer.mrBT40};
  }
  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`
